import styled from '@emotion/styled';
import { EnHeading } from 'components/atoms/enHeading';
import { TextButton } from 'components/atoms/textButton';
import { IndexLink } from 'components/molecules/indexLink';
import { ProjectCard } from 'components/molecules/projectCard';
import { StudioCard } from 'components/molecules/studioCard';
import { TopicsCard } from 'components/molecules/topicsCard';
import FadeUpSection from 'components/organisms/fadeUpSection';
import InquiryBlock from 'components/organisms/inquiryBlock';
import { LoopCarousel } from 'components/organisms/loopCarousel';
import ParticleImageManager from 'components/organisms/particleCanvas/ParticleImageManager';
import ProjectCardCol from 'components/organisms/projectCardCol';
import Service from 'components/organisms/service';
import { TopicsGroup } from 'components/organisms/topicsGroup';
import dayjs from 'dayjs';
import { graphql } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player';
import { default as color, default as colors } from 'style/colors';
import fonts from 'style/fonts';
import { contentWrapper } from 'style/mixin';
import sizes from 'style/sizes';
import { baseTransitionSecond, breakpoint, easeOut, mq } from 'style/variables';
import { IndexEnQuery } from 'types/graphql-types';
import SEO from 'util/seo';

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface IndexPageProps {
  location: {
    pathname: string;
  };
  data: IndexEnQuery;
}

const topmovie =
  'https://player.vimeo.com/video/968790245?h=69aaf994ae&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';
const topmoviesp =
  'https://player.vimeo.com/video/969532758?h=08170d2539&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';

const ContentsWrapper = styled.div`
  max-width: 1860px;
  margin-right: auto;
  margin-left: auto;
`;

const MainVisualWrapper = styled.div`
  position: relative;
  padding-bottom: 40px;
`;

const Mainvisual = styled.div`
  position: relative;
  ${mq.onlypc} {
    width: 100%;
    aspect-ratio: 16 / 9;
    margin: ${sizes.header.height.pc} 0 0;
    overflow: hidden;
  }
  ${mq.onlysp} {
    width: 100%;
    aspect-ratio: 3 / 4;
    margin: ${sizes.header.height.sp} 0 0;
    overflow: hidden;
    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }
`;

const MainvisualMovie = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
  transition: opacity ${baseTransitionSecond}s ease-in-out;
  body.is-rendered & {
    opacity: 1;
  }
  & > div {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  ${mq.onlypc} {
    video {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    &.is-portrait {
      video {
        width: auto !important;
      }
    }
    &:not(.is-portrait) {
      video {
        height: auto !important;
      }
    }
  }
`;

const MessageOnMainvisualMovie = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity ${baseTransitionSecond}s ease-in-out;
  body.is-rendered & {
    opacity: 1;
  }
  ${mq.onlypc} {
    width: 742px;
  }
  ${mq.onlysp} {
    width: 270px;
  }
`;

const ScrollDown = styled('div')`
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
  opacity: 0;
  transition: opacity ${baseTransitionSecond}s ease-in-out;
  ${mq.onlypc} {
    bottom: 10px;
    &.is-show {
      opacity: 1;
    }
  }
  ${mq.onlysp} {
    bottom: -45px;
    &.is-show {
      opacity: 0;
    }
  }
`;

const ScrollBar = styled('span')`
  width: 1px;
  height: 54px;
  display: block;
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #dbdbdb;
  overflow: hidden;
  opacity: 0;
  ${mq.onlysp} {
    display: none;
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    height: 22px;
    background-color: ${colors.black01};
    left: 0;
    right: 0;
    top: 0;
    animation: scrollDown01 3s linear infinite;
  }
  @keyframes scrollDown01 {
    0% {
      top: -22px;
    }
    68% {
      top: 76px;
    }
    99% {
      top: 76px;
    }
    100% {
      bottom: -76px;
      top: auto;
    }
  }
`;

const TopContent = styled.div`
  overflow: hidden;
  position: relative;
  margin-top: 0px;
  transition: margin ${baseTransitionSecond * 3}s ${easeOut};
  z-index: 8001;
  ${mq.onlypc} {
    margin-bottom: ${sizes.margin[124]};
  }
  ${mq.onlysp} {
    margin-bottom: ${sizes.margin[120]};
  }
  .is-opening-end & {
    margin-top: -32px;
  }
`;
const ScrollTxt = styled('p')`
  position: relative;
  margin-bottom: 14px;
  font-family: ${fonts.enGothic};

  font-size: 1.5rem;
  height: 1.5rem;
  line-height: 1;
  letter-spacing: 1px;
  color: ${color.white01};
  ${mq.onlysp} {
    font-size: 8px;
    height: 8px;
    margin-bottom: 0;
    opacity: 0.5;
  }
  & > span {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    &:first-of-type {
      color: ${color.white01};
      opacity: 0;
      #mainvisual-scroll-down.is-show & {
        ${mq.onlypc} {
          opacity: 1;
        }
      }
    }
  }
`;

const LoadingText = styled('div')`
  pointer-events: none;
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  opacity: 1;
  transition: opacity ${baseTransitionSecond}s ease-in-out;

  body.is-rendered & {
    opacity: 0;
  }

  & > span {
    width: 100%;
    height: 1.5rem;
    font-family: ${fonts.enGothic};

    font-size: 1.2rem;
    line-height: 1;
    letter-spacing: 1px;
    color: ${color.gray07};

    animation: flash 1.6s ease-out infinite;
    @keyframes flash {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
`;

const SoundButton = styled.button`
  position: absolute;
  font-family: ${fonts.enGothic};

  opacity: 0;
  transition: opacity ${baseTransitionSecond}s ease-in-out;
  body.is-rendered & {
    opacity: 1;
  }
  ${mq.onlypc} {
    bottom: 0;
    right: 46px;
  }
  ${mq.onlysp} {
    bottom: 0;
    right: 18px;
  }
`;

const TopicsWrap = styled.div`
  ${contentWrapper}
  ${mq.onlypc} {
    margin-top: ${sizes.margin[100]};
    padding: 0;
  }
  ${mq.onlysp} {
    margin-top: ${sizes.margin[80]};
    padding: 0;
  }
`;

const StudioWrap = styled.div`
  ${mq.onlypc} {
    margin-top: ${sizes.margin[100]};
  }
  ${mq.onlysp} {
    margin-top: ${sizes.margin[80]};
  }
`;

const SectionTitleWrap = styled.div`
  text-align: center;
  ${mq.onlypc} {
    margin-bottom: ${sizes.margin[48]};
  }
  ${mq.onlysp} {
    margin-bottom: ${sizes.margin[32]};
  }
`;

const ProjectsWrap = styled.div`
  ${contentWrapper}
  margin-top: ${sizes.margin[100]};
  ${mq.onlysp} {
    margin-top: ${sizes.margin[80]};
    padding: 0;
  }
`;

const ProjectTitle = styled('div')`
  width: 100%;
  margin: ${sizes.margin[100]} auto 0;
  ${mq.onlysp} {
    margin-top: ${sizes.margin[80]};
    padding: 0 ${sizes.margin[32]};
  }
`;

const TopicsTitle = styled('div')`
  width: 100%;
  margin: 0 auto;
  ${mq.onlysp} {
    padding: 0 ${sizes.margin[32]};
  }
`;

const StudiosTitle = styled('div')`
  width: 100%;
  margin: 0 auto;
  ${mq.onlysp} {
    padding: 0 ${sizes.margin[32]};
  }
`;

const ViewMoreLinkWrap = styled(IndexLink)`
  display: block;
  margin-top: ${sizes.margin[48]};
  text-align: center;

  ${mq.onlysp} {
    margin-top: ${sizes.margin[16]};
    padding-bottom: 0;
  }
`;

const Pages: React.FC<IndexPageProps> = ({ data }) => {
  const topics = data.allShifterTopicsEnData.edges;
  const studios = data.allShifterStudiosData.edges;
  const projects = data.projects.edges;
  const pickups = data?.shifterPickupProjects?.acf?.pickup || [];
  const pickupProjects = pickups
    .map(pickup => projects?.filter(project => project?.node?.title?.rendered === pickup?.post_title))
    .flat();
  const [isSp, setIsSp] = useState(false);
  const [isPortrait, setIsPortrait] = useState(false);
  const timestamp = new Date().getTime();
  const movieW = 960;
  const movieH = 540;
  const resizeHandler = useCallback(() => {
    setIsSp(window.innerWidth <= breakpoint);
    setIsPortrait(window.innerWidth / window.innerHeight < movieW / movieH);
  }, []);

  const scrollHandler = useCallback(() => {
    if ((isSp ? window.innerWidth : window.innerHeight) > window.pageYOffset) {
      document.body.classList.add('is-pagetop');
    } else {
      document.body.classList.remove('is-pagetop');
    }
  }, []);

  let isStaticSp = false;
  if (typeof window !== 'undefined') isStaticSp = window.innerWidth <= breakpoint;

  useEffect(() => {
    document.body.classList.add('is-noscroll');

    resizeHandler();
    window.addEventListener('resize', resizeHandler);
    if ((isSp ? window.innerWidth : window.innerHeight) > window.pageYOffset) document.body.classList.add('is-pagetop');
    window.addEventListener('scroll', scrollHandler);

    return () => {
      document.body.classList.remove('is-pagetop');
      document.body.classList.remove('is-noscroll');
      document.body.classList.remove('is-rendered');

      window.removeEventListener('resize', resizeHandler);
      window.removeEventListener('scroll', scrollHandler);
      ParticleImageManager.delete();
    };
  }, []);

  const start = () => {
    document.getElementById('mainvisual-scroll-down')?.classList.add('is-show');
    document.body.classList.remove('is-noscroll');
    document.body.classList.add('is-rendered');
  };

  const onStart = () => {
    if (ParticleImageManager.isStart()) {
      start();
      return;
    }
    ParticleImageManager.loadAll().then(() => {
      ParticleImageManager.generateAll().then(() => {
        ParticleImageManager.start();
        start();
      });
    });
  };

  const [videoMuted, toggleVideoMuted] = useState(true);

  return (
    <>
      <SEO en />
      <Helmet
        bodyAttributes={{
          'data-page': 'top',
        }}
      />
      <ContentsWrapper>
        <MainVisualWrapper>
          <Mainvisual>
            <LoadingText>
              <span>Loading...</span>
            </LoadingText>
            <MainvisualMovie className={isPortrait ? 'is-portrait' : undefined}>
              <ReactPlayer
                url={isSp ? topmoviesp : topmovie}
                playing={true}
                playsinline
                width={'100%'}
                height={'100%'}
                loop={true}
                onStart={onStart}
                volume={videoMuted ? 0 : 1}
              ></ReactPlayer>
            </MainvisualMovie>
          </Mainvisual>
          {/* <ScrollDown id="mainvisual-scroll-down">
            <ScrollTxt>
              <span>Scroll Down</span>
            </ScrollTxt>
            <ScrollBar></ScrollBar>
          </ScrollDown> */}

          {/* <SoundButton
            onClick={() => {
              toggleVideoMuted(!videoMuted);
            }}
          >
            Sound&nbsp;{videoMuted ? ' ON' : ' OFF'}
          </SoundButton> */}
        </MainVisualWrapper>

        <TopContent>
          <Service pageLang="en" top />

          <ProjectsWrap>
            <SectionTitleWrap>
              <ProjectTitle>
                <EnHeading>Projects</EnHeading>
              </ProjectTitle>
            </SectionTitleWrap>
            <ProjectCardCol col={2}>
              {pickupProjects?.map(project => (
                <FadeUpSection key={`project-${project.node.id}`}>
                  <ProjectCard
                    fromTop={true}
                    size={'large'}
                    imgpath={[
                      project.node.acf?.kv_sp || 'noimage/img-card-kv-sp.jpg',
                      project?.node.acf?.kv || 'noimage/img-card-kv.jpg',
                    ]}
                    title={project.node.acf?.headline_en || 'No Title'}
                    client={project.node.acf?.client_abbr?.en || undefined}
                    work={project?.node?.acf?.product?.en || undefined}
                    tags={project.node.acf?.outline as string[]}
                    to={`/en/projects/${project.node.slug}`}
                    isEn
                  />
                </FadeUpSection>
              ))}
            </ProjectCardCol>
            <ViewMoreLinkWrap to={'/en/projects'}>
              <TextButton isBlock>Our Projects</TextButton>
            </ViewMoreLinkWrap>
          </ProjectsWrap>

          <TopicsWrap>
            <SectionTitleWrap>
              <TopicsTitle>
                <EnHeading>Topics</EnHeading>
              </TopicsTitle>
            </SectionTitleWrap>

            <FadeUpSection>
              <TopicsGroup spslide>
                {topics?.map(topics => {
                  return (
                    <TopicsCard
                      key={topics.node.id}
                      title={topics?.node.title?.rendered || 'NO TITLE'}
                      date={dayjs(topics?.node.acf?.launch || undefined).format('YYYY.MM.DD')}
                      to={`/en/topics/${topics?.node.slug}`}
                      imgpath={topics?.node.acf?.thumb || 'noimage/img-card-thumb.jpg'}
                      category={topics?.node.acf?.category || ''}
                      isEn
                    />
                  );
                })}
              </TopicsGroup>

              <ViewMoreLinkWrap to={'/en/topics'}>
                <TextButton isBlock>View More</TextButton>
              </ViewMoreLinkWrap>
            </FadeUpSection>
          </TopicsWrap>

          <StudioWrap>
            <SectionTitleWrap>
              <StudiosTitle>
                <EnHeading>Studios</EnHeading>
              </StudiosTitle>
            </SectionTitleWrap>

            <FadeUpSection>
              <LoopCarousel slidesToShow={2.4} padding={82}>
                {studios.map(studio => (
                  <StudioCard
                    key={studio.node.id}
                    imgpath={studio.node.acf?.thumb || 'noimage/img-card-thumb.jpg'}
                    {...(studio.node.acf?.detail_page ? { to: `/en/about/studios/${studio.node.slug}` } : {})}
                    place={studio.node.title?.rendered || ''}
                  />
                ))}
              </LoopCarousel>

              <ViewMoreLinkWrap to={'/en/about/studios'}>
                <TextButton isBlock>View More</TextButton>
              </ViewMoreLinkWrap>
            </FadeUpSection>
          </StudioWrap>
        </TopContent>
        <InquiryBlock top en isStaticSp={isStaticSp} />
      </ContentsWrapper>
    </>
  );
};

export default Pages;

export const pageQuery = graphql`
  query IndexEn {
    site {
      siteMetadata {
        title
      }
    }
    shifterPickupProjects: shifterPickupProjectsEn(title: { rendered: { eq: "allpickupprojects" } }) {
      acf {
        pickup {
          post_title
        }
      }
    }
    projects: allShifterProjectsData(
      sort: { fields: date, order: DESC }
      filter: { title: { rendered: { nin: ["dummy", null] } } }
    ) {
      edges {
        node {
          id
          title {
            rendered
          }
          slug
          acf {
            headline_en
            client_abbr {
              ja
              en
            }
            services {
              post_title
            }
            kv
            kv_sp
            outline
            pickup
            product {
              ja
              en
            }
          }
        }
      }
    }
    allShifterTopicsEnData(
      sort: { fields: acf___launch, order: DESC }
      filter: { title: { rendered: { nin: ["dummy", null] } } }
      limit: 6
    ) {
      edges {
        node {
          id
          title {
            rendered
          }
          acf {
            category
            thumb
            launch
          }
          slug
        }
      }
    }
    allShifterStudiosData(
      filter: { title: { rendered: { nin: ["dummy", null] } } }
      sort: { order: ASC, fields: acf___show_num }
    ) {
      edges {
        node {
          id
          title {
            rendered
          }
          slug
          acf {
            thumb
            detail_page
          }
        }
      }
    }
  }
`;
